import {runInAction} from 'mobx';
import React, {useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button,
    Card,
    Checkbox,
    Col,
    ConfigProvider,
    Drawer,
    Image,
    Input,
    Modal,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Tag,
    Tooltip
} from 'antd';
import {Content} from "antd/es/layout/layout";
import API from "../../../api/user";
import exchanges from "../Connectors/exchanges";
import {
    AndroidOutlined,
    AreaChartOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    ClearOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    FullscreenExitOutlined,
    InfoCircleOutlined,
    InfoCircleTwoTone,
    LinkOutlined,
    OrderedListOutlined,
    PlusCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import {mobXStore, observer} from "../../../store/";
import {showModalDemoWarning, showModalLimitWarning} from "../../../Helpers";
import StrategyCardLite from "../../components/StrategyCardLite";
import TradesList from "../../components/TradesList";
import SignalLogs from "../../components/SignalLogs";
import EquityStats from "../../components/EquityStats/EquityStats";
import {useSearchParams} from "react-router-dom";

const LIMIT_BOTS = 0;

const content_msg = {
    "How to set up copying trades": {
        "en": "How to set up copying trades from a strategy to a crypto exchange?",
        "ua": "Як налаштувати копіювання угод з стратегії на криптобіржу?",
        "ru": "Как настроить копирование сделок из стратегии на криптобиржу?"
    },
}

const formatNumber = (value, column) => {
    if (typeof value !== 'number') return value;

    const formattedValue = Number(Number(value).toFixed(2)).toString();
    const prefix = column.summaryPrefix || '';
    const suffix = column.summarySuffix || '';

    return `${prefix}${formattedValue}${suffix}`;
};

const filterOption = (input, option) =>
    (option?.search_label ?? '').toLowerCase().includes(input.toLowerCase());

function get_strategy_id(strategy_id) {
    if (strategy_id > 1000) {
        return '#ID_' + strategy_id;
    } else if (strategy_id > 100) {
        return '#ID_0' + strategy_id;
    } else if (strategy_id > 10) {
        return '#ID_00' + strategy_id;
    } else {
        return '#ID_000' + strategy_id;
    }
}

// BotsExchange component
function BotsExchange({addNewCopyBot}) {

    const language = mobXStore.getKey('language');

    const [queryParameters] = useSearchParams()

    const apiUser = new API();

    const [saveOsActiveAcceptTerms, setSaveOsActiveAcceptTerms] = useState(false);

    const [equityStatsPeriod, setEquityStatsPeriod] = useState(1825);
    const [equityCurve, setEquityCurve] = useState(null);
    const [strategy_ids, setStrategyIds] = useState([]);
    const [isShowTradeTable, setIsShowTradeTable] = useState(false);

    const [maxLossStreakCount, setMaxLossStreakCount] = useState(0);
    const [maxDrawdownInSeries, setMaxDrawdownInSeries] = useState(0);
    const [maxSingleTradeLoss, setMmxSingleTradeLoss] = useState(0);
    const [maxOpenTradesLong, setMaxOpenTradesLong] = useState(0);
    const [maxOpenTradesShort, setMaxOpenTradesShort] = useState(0);
    const [maxOpenTradesBoth, setMaxOpenTradesBoth] = useState(0);

    const [tradesListOpen, setTradesListOpen] = useState(false);
    const [logsListOpen, setLogsListOpen] = useState(false);

    const [showAddMultipleBotModal, setShowAddMultipleBotModal] = useState(false);
    const [showAddBotModal, setShowAddBotModal] = useState(false);
    const [showEditBotModal, setShowEditBotModal] = useState(false);

    const [showFuturesInputFields, setShowFuturesInputFields] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const [demoMode, setDemoMode] = useState(false);

    const [editRecordId, setEditRecordId] = useState(0);
    const [inputBotName, setInputBotName] = useState('');
    const [inputExchangeId, setInputExchangeId] = useState(0);
    const [inputStrategyId, setInputStrategyId] = useState(0);
    const [inputOrderAmount, setInputOrderAmount] = useState(100);
    const [inputLeverage, setInputLeverage] = useState(1);
    const [inputMarginMode, setInputMarginMode] = useState('cross');
    const [inputClosePositionMode, setInputClosePositionMode] = useState('CLOSE');

    const [currentConnectorId, setCurrentConnectorId] = useState(0);

    const [dataSourceExchange, setDataSourceExchange] = useState([]);
    const [strategyList, setStrategyList] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const [selectedStrategy, setSelectedStrategy] = useState({});

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [multipleStrategiesIds, setMultipleStrategiesIds] = useState([]);
    const [selectedStrategyId, setSelectedStrategyId] = useState([]);
    const [selectedStrategyName, setSelectedStrategyName] = useState('');

    const [selectedConnectorId, setSelectedConnectorId] = useState(0);
    const [userSelectConnector, setUserSelectConnector] = useState({
        value: 0,
        label: 'All'
    });

    const [apiNotification, contextHolder] = notification.useNotification();

    const openErrorNotification = (message) => {
        apiNotification.error({
            type: 'error',
            message: 'Error',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const openSuccessNotification = (message) => {
        apiNotification.open({
            type: 'success',
            message: 'Success',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const sortIcon = (sorted) => {
        // alert(JSON.stringify(sorted))
        if (sorted['sortOrder'] === 'descend') {
            return (<CaretDownOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else if (sorted['sortOrder'] === 'ascend') {
            return (<CaretUpOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else {
            return (<FullscreenExitOutlined
                style={{
                    color: 'white',
                }}
            />)
        }
    }

    const getColumnSearchProps = (dataIndex, tooltip_text = '') => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    // backgroundColor: '#0f0fa8',
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Space direction={'horizontal'}>
                    <Input
                        ref={searchInput}
                        placeholder={tooltip_text}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            width: 200,
                            display: 'block',
                        }}
                    />
                    <Button
                        type="button"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        <CloseOutlined/>
                    </Button>
                </Space>
                <br/>
                <Space direction={'horizontal'}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && (handleReset(clearFilters) || handleSearch(selectedKeys, confirm, dataIndex) || close())}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        <ClearOutlined/> Reset
                    </Button>
                    {/*<Button*/}
                    {/*    type="link"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => {*/}
                    {/*        confirm({*/}
                    {/*            closeDropdown: false,*/}
                    {/*        });*/}
                    {/*        setSearchText(selectedKeys[0]);*/}
                    {/*        setSearchedColumn(dataIndex);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Filter*/}
                    {/*</Button>*/}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#f6adb5' : 'white',
                }}
            />
        ),
        sortIcon: (sorted) => sortIcon(sorted),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // render: (text) =>
        //     searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{
        //                 backgroundColor: '#ffc069',
        //                 padding: 0,
        //             }}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
    });

    useEffect(() => {

        runInAction(() => {
            if (!queryParameters.has('copy')) {
                mobXStore.setKey('addStrategyID', [])
            }

            addNewCopyBot(false);
            setDemoMode(mobXStore.getKey('demo_mode'));
            setInitialized(false);
            getUserExchange();
            getUserBots();
            getStrategy();
        });

        setTimeout(() => {
            // setStrategyIds(dataSource.map((item) => item.is_active ? item.strategy_id : 0));
            // alert(JSON.stringify(strategy_ids));
            // getEquityCurve(strategy_ids);
            setInitialized(true);
        }, 1000);
    }, []);

    useEffect(() => {
        setStrategyIds(dataSource.map((item) => item.is_active ? item.strategy_id : 0));
        getEquityCurve(strategy_ids || []);
    }, [dataSource, equityStatsPeriod]);

    useEffect(() => {
        getEquityCurve(strategy_ids || []);
    }, [strategy_ids]);

    useEffect(() => {
        getUserBots();
        getStrategy();
    }, [userSelectConnector]);

    useEffect(() => {
        console.log('...');
    }, [strategyList, multipleStrategiesIds, showAddMultipleBotModal, showAddBotModal]);

    function getUserExchange() {
        setLoading(true);
        apiUser.getUserExchange().then((response) => {
            if (response.result) {
                // console.log(response);
                const res = JSON.parse(response.data);
                res.forEach((item) => {
                    let exchange = exchanges.find((exchange) => exchange.ccxt_name === item.exchange);
                    item.exchange_name = exchange.name;
                    item.logo = exchange.logo;
                });
                setDataSourceExchange(res);
                // alert(JSON.stringify(res.length))

                // Если один коннектор, сразу всовываем его в выбор
                const active_connectors = res.filter((item) => item.is_active === 1);
                if (active_connectors.length === 1) {
                    setSelectedConnectorId(active_connectors[0].key);
                    if (active_connectors[0].type === 'future') {
                        setShowFuturesInputFields(true);
                    } else {
                        setShowFuturesInputFields(false);
                    }
                    setInputExchangeId(active_connectors[0].key)
                }

                // console.log(response.data);
                setLoading(false);
            } else {
                // console.log(response.error);
                setLoading(false);
                if (response.error !== undefined) {
                    openErrorNotification(response.error);
                } else {
                    openErrorNotification("Connection error. You must log in again.");
                }
                apiUser.logout();
            }
        });
    }

    function getStrategy() {

        // if (!demoMode && dataSource.length >= LIMIT_BOTS) {
        //     setShowAddBotModal(false);
        //     showModalLimitWarning();
        //     return;
        // }

        setLoading(true);
        apiUser.getStrategy().then((response) => {
            if (response.result) {

                // market = 'CRYPTO' skip
                const res = JSON.parse(response.data).filter((item) => item.market === 'CRYPTO');
                setStrategyList(res);

                // Show Add Bot Modal
                const stID = mobXStore.getKey('addStrategyID')
                if (stID.length === 1) {
                    setShowAddMultipleBotModal(false);
                    setInputStrategyId(stID[0]);
                    const ss = getStrategyById(stID[0], res)
                    if (ss) {
                        setSelectedStrategy(ss);
                        setInputBotName(ss.id + '-' + ss.name + '-' + ss.symbol.replace('BINANCE:', '').replace('.P', '').replace('USDT', '/USDT'));
                    }
                    setShowAddBotModal(true);
                    setShowAddMultipleBotModal(false);
                    mobXStore.setKey('addStrategyID', []);
                } else if (stID.length > 1) {
                    setMultipleStrategiesIds(stID);
                    setShowAddBotModal(false);
                    setShowAddMultipleBotModal(true);
                }
                setLoading(false);
                // console.log(response.data);
            } else {
                // console.log(response.error);
                setLoading(false);

                if (response.error !== undefined) {
                    openErrorNotification(response.error);
                } else {
                    openErrorNotification("Connection error. You must log in again.");
                }

                setTimeout(() => {
                    apiUser.logout();
                }, 2000);
            }
        });
    }

    function getEquityCurve(strategy_ids = []) {
        if (strategy_ids.length === 0) {
            setMaxLossStreakCount(0);
            setMaxDrawdownInSeries(0);
            setMmxSingleTradeLoss(0);
            setEquityCurve([]);
            return;
        }

        // setLoading(true);
        apiUser.getEquity({
            'data': 'getEquityCurve',
            'strategy_ids': strategy_ids,
            'period_in_days': equityStatsPeriod
        }).then((response) => {
            if (response.result) {
                setEquityCurve(JSON.parse(response.data));
                // console.log(JSON.parse(response.data));
            } else {
                setEquityCurve([]);
            }
            // setLoading(false);
        });

        apiUser.getEquityStats({
            'data': 'getEquityStats',
            'strategy_ids': strategy_ids,
            'period_in_days': equityStatsPeriod
        }).then((response) => {
            if (response.result) {
                const rez = JSON.parse(response.data)[0];
                if (rez) {
                    setMaxLossStreakCount(rez.max_loss_streak_count);
                    setMaxDrawdownInSeries(rez.max_drawdown_in_series);
                    setMmxSingleTradeLoss(rez.max_single_trade_loss);
                } else {
                    setMaxLossStreakCount(0);
                    setMaxDrawdownInSeries(0);
                    setMmxSingleTradeLoss(0);
                }
            }
        });

        apiUser.getMaxOpenTrades({
            'data': 'getMaxOpenTrades',
            'strategy_ids': strategy_ids,
            'period_in_days': equityStatsPeriod
        }).then((response) => {
            if (response.result) {
                // alert(JSON.stringify(response.data));
                const rez = JSON.parse(response.data)[0];
                if (rez) {
                    setMaxOpenTradesLong(rez.max_concurrent_long);
                    setMaxOpenTradesShort(rez.max_concurrent_short);
                    setMaxOpenTradesBoth(rez.max_concurrent_both);
                } else {
                    setMaxOpenTradesLong(0);
                    setMaxOpenTradesShort(0);
                    setMaxOpenTradesBoth(0);
                }
            }
        });
    }

    // get Strategy by ID
    function getStrategyById(id = null, strategyList_ = []) {
        if (!id) {
            if (strategyList_.length === 0) {
                return strategyList.filter((item) => item.id === inputStrategyId)[0];
            } else {
                return strategyList_.filter((item) => item.id === inputStrategyId)[0];
            }
        } else {
            if (strategyList_.length === 0) {
                return strategyList.filter((item) => item.id === id)[0];
            } else {
                return strategyList_.filter((item) => item.id === id)[0];
            }
        }
    }

    useEffect(() => {
        setSelectedStrategy(getStrategyById());
    }, [inputStrategyId]);

    function getUserBots() {
        setLoading(true);
        apiUser.getBots({connector_id: currentConnectorId}).then((response) => {
            if (response.result) {
                // console.log(response);
                let res = JSON.parse(response.data);
                res.forEach((item) => {
                    let exchange = exchanges.find((exchange) => exchange.ccxt_name === item.exchange);
                    // item.exchange_name = exchange.name;
                    item.logo = exchange.logo;
                });

                if (res.length > 0) {
                    setDataSource(res);
                    setStrategyIds(dataSource.map((item) => item.is_active ? item.strategy_id : 0));
                } else {
                    setDataSource([]);
                    setStrategyIds([]);
                }

                setLoading(false);
                // console.log(response.data);
            } else {
                // console.log(response.error);
                setLoading(false);
                if (response.error !== undefined) {
                    openErrorNotification(response.error);
                } else {
                    openErrorNotification("Connection error. You must log in again.");
                }
                apiUser.logout();
            }
        });
    }


    function handleChangeActive(key, is_active) {
        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        setLoading(true);
        apiUser.changeActiveBot({id: key, is_active: is_active}).then((response) => {
            if (response.result) {
                getUserBots();
                openSuccessNotification('Bot status changed successfully');
                // Modal.success({
                //     title: 'Success',
                //     content: 'Bot status changed successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot status change error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    function handleDeleteBoot(key) {

        // alert(key)

        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        setLoading(true);
        apiUser.deleteBot({id: key}).then((response) => {
            if (response.result) {
                getUserBots();
                openSuccessNotification('Bot deleted successfully');
                // Modal.success({
                //     title: 'Success',
                //     content: 'Bot deleted successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot delete error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // Show modal for edit Bot properties
    function handleEdit(record) {

        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        setEditRecordId(record.ID);
        setInputBotName(record.name);
        setInputExchangeId(record.exchange_id);
        setInputOrderAmount(record.order_amount_usd);
        setInputClosePositionMode(record.close_position_mode);
        setInputLeverage(record.leverage);
        setInputMarginMode(record.margin_mode);

        if (record.exchange_market === 'future') {
            setShowFuturesInputFields(true);
        } else {
            setShowFuturesInputFields(false);
        }

        setShowEditBotModal(true);
    }

    // saveNewBotToDB
    function saveNewBotToDB() {
        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        if (!inputBotName) {
            Modal.error({
                title: 'Error',
                content: 'Bot name is required',
            });
            return;
        }

        if (!inputExchangeId) {
            Modal.error({
                title: 'Error',
                content: 'Exchange is required',
            });
            return;
        }

        if (!inputStrategyId) {
            Modal.error({
                title: 'Error',
                content: 'Strategy is required',
            });
            return;
        }

        setShowAddBotModal(false);

        if (!inputOrderAmount || inputOrderAmount <= 0) {
            Modal.error({
                title: 'Error',
                content: 'Order amount is required',
            });
            return;
        }

        const data = {
            name: inputBotName,
            strategy_id: inputStrategyId,
            exchange_connectors_id: inputExchangeId,
            order_amount_usd: inputOrderAmount,
            leverage: inputLeverage,
            margin_mode: inputMarginMode,
            close_position_mode: inputClosePositionMode
        }

        apiUser.addNewBot(data).then((response) => {
            if (response.result) {
                getUserBots();
                // Modal.success({
                //     title: 'Success',
                //     content: 'Bot created successfully',
                // });
                openSuccessNotification('Bot created successfully');
                runInAction(() => {
                    mobXStore.setKey('addStrategyID', []);
                })
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'This exchange has already created a bot with this strategy.' // + response.error,
                });
            }
            setLoading(false);
        });
    }

    // saveNewBotToDBMulti
    function saveNewBotToDBMulti() {
        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        if (multipleStrategiesIds.length === 0) {
            Modal.error({
                title: 'Error',
                content: 'Strategies not selected',
            });
            return;
        }

        if (!inputBotName) {
            Modal.error({
                title: 'Error',
                content: 'Bot name is required',
            });
            return;
        }

        if (!inputExchangeId) {
            Modal.error({
                title: 'Error',
                content: 'Exchange is required',
            });
            return;
        }

        setShowAddMultipleBotModal(false);

        if (!inputOrderAmount || inputOrderAmount <= 0) {
            Modal.error({
                title: 'Error',
                content: 'Order amount is required',
            });
            return;
        }

        for (let i = 0; i < multipleStrategiesIds.length; i++) {
            const data = {
                name: inputBotName + ' ' + multipleStrategiesIds[i],
                strategy_id: multipleStrategiesIds[i],
                exchange_connectors_id: inputExchangeId,
                order_amount_usd: inputOrderAmount,
                leverage: inputLeverage,
                margin_mode: inputMarginMode,
                close_position_mode: inputClosePositionMode
            }

            apiUser.addNewBot(data).then((response) => {
            });
        }

        runInAction(() => {
            mobXStore.setKey('addStrategyID', []);
        });

        getUserBots();
        // Modal.success({
        //     title: 'Success',
        //     content: 'Bots created successfully',
        // });
        openSuccessNotification('Bots created successfully');
        setLoading(false);
    }

    // editBotToDB
    function editBotToDB() {
        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        setShowEditBotModal(false);

        const data = {
            id: editRecordId,
            name: inputBotName,
            order_amount_usd: inputOrderAmount,
            leverage: inputLeverage,
            margin_mode: inputMarginMode,
            close_position_mode: inputClosePositionMode
        }

        apiUser.updateBot(data).then((response) => {
            if (response.result) {
                getUserBots();
                // Modal.success({
                //     title: 'Success',
                //     content: 'Bot update successfully',
                // });
                openSuccessNotification('Bot update successfully');
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot update error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // show modal for add new Bot (single)
    const modalAddBotSingle = () => {

        if (!initialized || !showAddBotModal) {
            return null;
        }

        if (demoMode && dataSource.length >= LIMIT_BOTS) {
            setShowAddBotModal(false);
            showModalLimitWarning();
            return null;
        }

        if (!strategyList || strategyList.length === 0) {
            return null;
        }

        if (!dataSourceExchange || dataSourceExchange.length === 0) {
            Modal.error({
                title: 'Error',
                content: 'You don\'t have any connected exchanges',
            });
            setShowAddBotModal(false);
            return null;
        }

        let exchanges_list = dataSourceExchange.map((item) => {
            if (item.is_active === 1) {
                return {
                    value: item.key,
                    type: item.type,
                    search_label: item.exchange.toUpperCase() + ' ' + item.type.toUpperCase() + ' ' + item.name,
                    label: <>
                        <span>{item.exchange.toUpperCase()} | {item.type.toUpperCase()} |</span><strong> {item.name}</strong></>
                }
            } else {
                return {};
            }
        });

        exchanges_list = exchanges_list.filter((item) => Object.keys(item).length !== 0);

        if (exchanges_list.length === 0) {
            Modal.error({
                title: 'Error',
                content: 'You don\'t have any active connected exchanges.',
            });
            setShowAddBotModal(false);
            return null;
        }

        let strategy_list = strategyList.map((item) => {
            return {
                value: item.id,
                search_label: item.name + ' ' + item.symbol + ' ' + item.timeframe,
                label: item.name + '-' + item.id + ' | ' + item.symbol.replace('BINANCE:', '').replace('.P', '').replace('USDT', '/USDT') + ' | TF:' + item.timeframe
            }
        });

        return (
            <Modal
                key="modalAddBotSingle"
                open={showAddBotModal}
                title={<strong>🆕 Create Copy Bot</strong>}
                style={{
                    marginTop: "-90px",
                }}
                height={600}
                width={720}
                onOk={() => saveNewBotToDB()}
                onCancel={() => {
                    runInAction(() => {
                        setMultipleStrategiesIds([]);
                        mobXStore.setKey('addStrategyID', []);
                    });
                    setShowAddBotModal(false);
                }}
            >
                <Card
                    // style={{
                    //     marginTop: "-50px",
                    // }}
                >
                    <Space
                        direction="vertical"
                        size="small"
                        style={{
                            display: 'flex',
                        }}>
                        <Input.Group compact>
                            <Input disabled value="Bot name"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputBotName(e.target.value)}
                                placeholder="Bot name"
                                value={inputBotName}
                                style={{width: '75%'}}
                                defaultValue={inputBotName}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Strategy"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                showSearch
                                onChange={(value) => {
                                    setInputStrategyId(value);
                                }}
                                placeholder="Select Strategy"
                                value={inputStrategyId !== 0 ? inputStrategyId : null}
                                style={{width: '75%'}}
                                options={strategy_list}
                                filterOption={filterOption}
                                // defaultValue={strategy_list[0].value}

                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Exchange"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                showSearch
                                onChange={(value) => {
                                    setShowFuturesInputFields(exchanges_list.find((exchange) => exchange.value === value).type === 'future');
                                    setInputExchangeId(value);
                                }}
                                placeholder="Select Connector to exchange"
                                // value={inputExchangeId}
                                style={{width: '75%'}}
                                options={exchanges_list}
                                filterOption={filterOption}
                                defaultValue={selectedConnectorId !== 0 ? selectedConnectorId : null}
                            />
                        </Input.Group>
                        {showFuturesInputFields && <>
                            <Input.Group compact>
                                <Input disabled value="Margin mode"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputMarginMode(value)}
                                    style={{width: '75%'}}
                                    value={inputMarginMode}
                                    options={[
                                        {value: 'isolated', label: <span>Isolated</span>},
                                        {value: 'cross', label: <span>Cross</span>}
                                    ]}
                                    defaultValue={"isolated"}
                                />
                            </Input.Group>
                            <Input.Group compact>
                                <Input disabled value="Leverage"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputLeverage(value)}
                                    style={{width: '75%'}}
                                    value={inputLeverage}
                                    options={[
                                        // {value: 0, label: <span>From the strategy</span>},
                                        {value: 1, label: <span>1</span>},
                                        {value: 2, label: <span>2</span>},
                                        {value: 3, label: <span>3</span>},
                                        {value: 4, label: <span>4</span>},
                                        {value: 5, label: <span>5</span>},
                                        {value: 6, label: <span>6</span>},
                                        {value: 7, label: <span>7</span>},
                                        {value: 8, label: <span>8</span>},
                                        {value: 9, label: <span>9</span>},
                                        {value: 10, label: <span>10</span>},
                                        {value: 15, label: <span>15</span>},
                                        {value: 20, label: <span>20</span>},
                                        {value: 30, label: <span>30</span>},
                                        {value: 40, label: <span>40</span>},
                                        {value: 50, label: <span>50</span>},
                                        {value: 100, label: <span>100</span>}
                                    ]}
                                    defaultValue={1}
                                />
                            </Input.Group>
                        </>}
                        <Input.Group compact>
                            <Input disabled value="Order amount, USD"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputOrderAmount(e.target.value)}
                                value={inputOrderAmount}
                                placeholder="Order amount, USD"
                                style={{width: '70%'}}
                                defaultValue='100'
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '500px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        The order size in USD that will be opened on the exchange. When using 1
                                        leverage (no leverage), the order size will be equal to the margin deposit. With
                                        2 leverage, the margin deposit will be 50% of the order size.<br/><br/>

                                        For example, if you want to open a position of 1000 USD:<br/>
                                        * With 1 leverage, you will need 1000 USD as a margin deposit.<br/>
                                        * With 2 leverage, you will need 500 USD as a margin deposit.<br/><br/>

                                        It's important to note that the minimum order size may vary depending on the
                                        exchange and the specific traded asset. Before opening a position, you should
                                        always check the minimum order size requirements on your chosen exchange for
                                        each specific asset. This will help avoid errors when placing orders and ensure
                                        your trading complies with the exchange's rules and restrictions.
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Close position mode"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                onChange={(value) => setInputClosePositionMode(value)}
                                style={{width: '70%'}}
                                value={inputClosePositionMode}
                                options={[
                                    {value: 'CLOSE', label: <span>CLOSE</span>},
                                    {value: 'REDUCE', label: <span>REDUCE</span>}
                                ]}
                                defaultValue={"CLOSE"}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '400px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        Select the mode of closing positions when the bot receives a signal to close a
                                        position.<br/>
                                        <strong>CLOSE</strong> - close all open positions on this instrument,<br/>
                                        <strong>REDUCE</strong> - reduce the position size by the order amount
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                        {selectedStrategy && <StrategyCardLite
                            strategy={{
                                'id': selectedStrategy.name + '-' + selectedStrategy.id,
                                'pnl_annual': selectedStrategy.net_profit_percentage,
                                'leverage': selectedStrategy.leverage,
                                'market': selectedStrategy.market,
                                'direction': selectedStrategy.direction,
                                'symbol': selectedStrategy.symbol.replace('BINANCE:', ''),
                                'draw_down': selectedStrategy.max_drawdown_percentage,
                                'max_loss_orders_session': selectedStrategy.max_loss_streak,
                                'win_rate': selectedStrategy.percent_profitable_all,
                                'profit_factor': selectedStrategy.profit_factor_all
                            }}
                        />}
                    </Space>
                </Card>
                <strong style={{color: "#ff0811"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    create the Copy Bot.</strong>
            </Modal>
        )
    }

    // show modal for add new Bot (single)
    const modalAddBotMultiple = () => {

        if (!initialized || !showAddMultipleBotModal) {
            return null;
        }

        if (!strategyList || strategyList.length === 0) {
            return null;
        }

        if (demoMode && dataSource.length >= LIMIT_BOTS) {
            setShowAddMultipleBotModal(false);
            showModalLimitWarning();
            return null;
        }

        if (!dataSourceExchange || dataSourceExchange.length === 0) {
            Modal.error({
                title: 'Error',
                content: 'You don\'t have any connected exchanges',
            });
            setShowAddMultipleBotModal(false);
            return null;
        }

        let exchanges_list = dataSourceExchange.map((item) => {
            if (item.is_active === 1) {
                return {
                    value: item.key,
                    type: item.type,
                    search_label: item.exchange.toUpperCase() + ' ' + item.type.toUpperCase() + ' ' + item.name,
                    label: <>
                        <span>{item.exchange.toUpperCase()} | {item.type.toUpperCase()} |</span><strong> {item.name}</strong></>
                }
            } else {
                return {};
            }
        });

        exchanges_list = exchanges_list.filter((item) => Object.keys(item).length !== 0);

        if (exchanges_list.length === 0) {
            Modal.error({
                title: 'Error',
                content: 'You don\'t have any active connected exchanges.',
            });
            setShowAddMultipleBotModal(false);
            return null;
        }

        return (
            <Modal
                key="modalAddBotMultiple"
                open={showAddMultipleBotModal}
                title={<strong>🆕 Create <span style={{color: 'red'}}>{multipleStrategiesIds.length}</span> Copy
                    Bots</strong>}
                // style={{
                //     marginTop: "-90px",
                // }}
                height={600}
                width={720}
                onOk={() => saveNewBotToDBMulti()}
                onCancel={() => {
                    runInAction(() => {
                        setMultipleStrategiesIds([]);
                        mobXStore.setKey('addStrategyID', []);
                    });
                    setShowAddMultipleBotModal(false);
                }}
            >
                <Card
                    // style={{
                    //     marginTop: "-50px",
                    // }}
                >
                    <Space
                        direction="vertical"
                        size="small"
                        style={{
                            display: 'flex',
                        }}>
                        <Input.Group compact>
                            <Input disabled value="Bot name"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputBotName(e.target.value)}
                                placeholder="Bot name"
                                value={inputBotName}
                                style={{width: '75%'}}
                                defaultValue={inputBotName}
                            />
                        </Input.Group>
                        {/*<Input.Group compact>*/}
                        {/*    <Input disabled value="Strategy"*/}
                        {/*           style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>*/}
                        {/*    <Select*/}
                        {/*        showSearch*/}
                        {/*        onChange={(value) => {*/}
                        {/*            setInputStrategyId(value);*/}
                        {/*        }}*/}
                        {/*        placeholder="Select Strategy"*/}
                        {/*        value={inputStrategyId !== 0 ? inputStrategyId : null}*/}
                        {/*        style={{width: '75%'}}*/}
                        {/*        options={strategy_list}*/}
                        {/*        filterOption={filterOption}*/}
                        {/*        // defaultValue={strategy_list[0].value}*/}

                        {/*    />*/}
                        {/*</Input.Group>*/}
                        <Input.Group compact>
                            <Input disabled value="Exchange"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                showSearch
                                onChange={(value) => {
                                    setShowFuturesInputFields(exchanges_list.find((exchange) => exchange.value === value).type === 'future');
                                    setInputExchangeId(value);
                                }}
                                placeholder="Select Connector to exchange"
                                // value={inputExchangeId}
                                style={{width: '75%'}}
                                options={exchanges_list}
                                filterOption={filterOption}
                                defaultValue={selectedConnectorId !== 0 ? selectedConnectorId : null}
                            />
                        </Input.Group>
                        {showFuturesInputFields && <>
                            <Input.Group compact>
                                <Input disabled value="Margin mode"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputMarginMode(value)}
                                    style={{width: '75%'}}
                                    value={inputMarginMode}
                                    options={[
                                        {value: 'isolated', label: <span>Isolated</span>},
                                        {value: 'cross', label: <span>Cross</span>}
                                    ]}
                                    defaultValue={"isolated"}
                                />
                            </Input.Group>
                            <Input.Group compact>
                                <Input disabled value="Leverage"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputLeverage(value)}
                                    style={{width: '75%'}}
                                    value={inputLeverage}
                                    options={[
                                        // {value: 0, label: <span>From the strategy</span>},
                                        {value: 1, label: <span>1</span>},
                                        {value: 2, label: <span>2</span>},
                                        {value: 3, label: <span>3</span>},
                                        {value: 4, label: <span>4</span>},
                                        {value: 5, label: <span>5</span>},
                                        {value: 6, label: <span>6</span>},
                                        {value: 7, label: <span>7</span>},
                                        {value: 8, label: <span>8</span>},
                                        {value: 9, label: <span>9</span>},
                                        {value: 10, label: <span>10</span>},
                                        {value: 15, label: <span>15</span>},
                                        {value: 20, label: <span>20</span>},
                                        {value: 30, label: <span>30</span>},
                                        {value: 40, label: <span>40</span>},
                                        {value: 50, label: <span>50</span>},
                                        {value: 100, label: <span>100</span>}
                                    ]}
                                    defaultValue={1}
                                />
                            </Input.Group>
                        </>}
                        <Input.Group compact>
                            <Input disabled value="Order amount, USD"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputOrderAmount(e.target.value)}
                                value={inputOrderAmount}
                                placeholder="Order amount, USD"
                                style={{width: '70%'}}
                                defaultValue='100'
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '500px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        The order size in USD that will be opened on the exchange. When using 1
                                        leverage (no leverage), the order size will be equal to the margin deposit. With
                                        2 leverage, the margin deposit will be 50% of the order size.<br/><br/>

                                        For example, if you want to open a position of 1000 USD:<br/>
                                        * With 1 leverage, you will need 1000 USD as a margin deposit.<br/>
                                        * With 2 leverage, you will need 500 USD as a margin deposit.<br/><br/>

                                        It's important to note that the minimum order size may vary depending on the
                                        exchange and the specific traded asset. Before opening a position, you should
                                        always check the minimum order size requirements on your chosen exchange for
                                        each specific asset. This will help avoid errors when placing orders and ensure
                                        your trading complies with the exchange's rules and restrictions.
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Close position mode"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                onChange={(value) => setInputClosePositionMode(value)}
                                style={{width: '70%'}}
                                value={inputClosePositionMode}
                                options={[
                                    {value: 'CLOSE', label: <span>CLOSE</span>},
                                    {value: 'REDUCE', label: <span>REDUCE</span>}
                                ]}
                                defaultValue={"CLOSE"}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '400px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        Select the mode of closing positions when the bot receives a signal to close a
                                        position.<br/>
                                        <strong>CLOSE</strong> - close all open positions on this instrument,<br/>
                                        <strong>REDUCE</strong> - reduce the position size by the order amount
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                    </Space>
                </Card>
                <strong style={{color: "#ff0811"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    create the Copy Bot.</strong>
            </Modal>
        )
    }

    // show modal for Edit Bot
    const modalEditBot = () => {

        if (!dataSourceExchange || dataSourceExchange.length === 0) {
            return null;
        }

        return (
            <Modal
                key="modalEditBot"
                open={showEditBotModal}
                title={<strong>📝 Edit Copy Bot</strong>}
                height={600}
                width={700}
                onOk={() => editBotToDB()}
                onCancel={() => setShowEditBotModal(false)}
            >
                <Card>
                    <Space
                        direction="vertical"
                        size="small"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Input.Group compact>
                            <Input disabled value="Bot name"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputBotName(e.target.value)}
                                placeholder="Bot name"
                                value={inputBotName}
                                style={{width: '75%'}}
                                defaultValue={inputBotName}
                            />
                        </Input.Group>
                        {showFuturesInputFields && <>
                            <Input.Group compact>
                                <Input disabled value="Margin mode"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputMarginMode(value)}
                                    style={{width: '75%'}}
                                    value={inputMarginMode}
                                    options={[
                                        {value: 'isolated', label: <span>Isolated</span>},
                                        {value: 'cross', label: <span>Cross</span>}
                                    ]}
                                    defaultValue={"isolated"}
                                />
                            </Input.Group>
                            <Input.Group compact>
                                <Input disabled value="Leverage"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputLeverage(value)}
                                    style={{width: '75%'}}
                                    value={inputLeverage}
                                    options={[
                                        // {value: 0, label: <span>From the strategy</span>},
                                        {value: 1, label: <span>1</span>},
                                        {value: 2, label: <span>2</span>},
                                        {value: 3, label: <span>3</span>},
                                        {value: 4, label: <span>4</span>},
                                        {value: 5, label: <span>5</span>},
                                        {value: 6, label: <span>6</span>},
                                        {value: 7, label: <span>7</span>},
                                        {value: 8, label: <span>8</span>},
                                        {value: 9, label: <span>9</span>},
                                        {value: 10, label: <span>10</span>},
                                        {value: 15, label: <span>15</span>},
                                        {value: 20, label: <span>20</span>},
                                        {value: 30, label: <span>30</span>},
                                        {value: 40, label: <span>40</span>},
                                        {value: 50, label: <span>50</span>},
                                        {value: 100, label: <span>100</span>}
                                    ]}
                                    defaultValue={1}
                                />
                            </Input.Group>
                        </>}
                        <Input.Group compact>
                            <Input disabled value="Order amount, USD"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputOrderAmount(e.target.value)}
                                value={inputOrderAmount}
                                placeholder="Order amount, USD"
                                style={{width: '70%'}}
                                defaultValue={inputOrderAmount}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '500px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        The order size in USD that will be opened on the exchange. When using 1
                                        leverage (no leverage), the order size will be equal to the margin deposit. With
                                        2 leverage, the margin deposit will be 50% of the order size.<br/><br/>

                                        For example, if you want to open a position of 1000 USD:<br/>
                                        * With 1 leverage, you will need 1000 USD as a margin deposit.<br/>
                                        * With 2 leverage, you will need 500 USD as a margin deposit.<br/><br/>

                                        It's important to note that the minimum order size may vary depending on the
                                        exchange and the specific traded asset. Before opening a position, you should
                                        always check the minimum order size requirements on your chosen exchange for
                                        each specific asset. This will help avoid errors when placing orders and ensure
                                        your trading complies with the exchange's rules and restrictions.
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Close position mode"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                onChange={(value) => setInputClosePositionMode(value)}
                                style={{width: '70%'}}
                                value={inputClosePositionMode}
                                options={[
                                    {value: 'CLOSE', label: <span>CLOSE</span>},
                                    {value: 'REDUCE', label: <span>REDUCE</span>}
                                ]}
                                defaultValue={"CLOSE"}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '400px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        Select the mode of closing positions when the bot receives a signal to close a
                                        position.<br/>
                                        <strong>CLOSE</strong> - close all open positions on this instrument,<br/>
                                        <strong>REDUCE</strong> - reduce the position size by the order amount
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                    </Space>
                </Card>
                <strong style={{color: "#ff0811"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    edit the Copy Bot.</strong>
            </Modal>
        )
    }

    const columns = [
        {
            key: 'is_active',
            title: 'Active',
            dataIndex: 'is_active',
            align: 'center',
            fixed: 'left',
            onCell: () => ({
                style: {
                    backgroundColor: '#eff0fc',
                }
            }),
            // width: '60px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.is_active - b.is_active,
            render: (_, record) => (<Switch size="middle" checked={record.is_active}
                                            onChange={() => {
                                                if (!record.is_active && !saveOsActiveAcceptTerms) {
                                                    Modal.confirm({
                                                        width: 650,
                                                        title: 'Activate Trading Signals',
                                                        icon: <ExclamationCircleOutlined/>,
                                                        content: (<>
                                                                <h4>To enable trading signals you must:</h4>
                                                                1. Agree to our <a
                                                                href={"https://docs.cdzv.com/code-zero-visual-trading_en/support/term-of-use"}
                                                                target={"_blank"}>Terms of use</a> and assume
                                                                all risks associated with cryptocurrency trading.<br/>
                                                                2. Confirm that you are not a US citizen.<br/><br/>

                                                                <Checkbox
                                                                    onChange={(e) => setSaveOsActiveAcceptTerms(e.target.checked)}>
                                                                    <span style={{color: '#617af8'}}>
                                                                    To remember my consent to activate trading signals
                                                                    and not to ask me about it.
                                                                    </span>
                                                                </Checkbox>

                                                                <br/><br/>

                                                                <Alert message="Important Disclaimer:"
                                                                       description="All presented strategies and signals are solely a demonstration of the platform's technical capabilities. We do not provide financial advice or investment recommendations. The use of any strategies in real trading is done at the user's own discretion and risk. Past performance does not guarantee future returns."
                                                                       type="warning"/>
                                                            </>
                                                        ),
                                                        okText: 'I agree',
                                                        cancelText: 'I don\'t agree',
                                                        onOk() {
                                                            handleChangeActive(record.ID, !record.is_active)
                                                        },
                                                        onCancel() {
                                                            // console.log('Cancel');
                                                        },
                                                    });
                                                } else {
                                                    handleChangeActive(record.ID, !record.is_active)
                                                }
                                            }
                                            }
            />)
        },
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            align: 'center',
            // width: '300px',
            fixed: 'left',
            onCell: () => ({
                style: {
                    // color: '#030342',
                    backgroundColor: '#eff0fc',
                }
            }),
            render: (text) => {
                return <strong>{text}</strong>
            },
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps('name', 'Search by Bot name'),
        },
        {
            key: 'symbol',
            title: 'Symbol',
            dataIndex: 'symbol',
            align: 'center',
            fixed: 'left',
            onCell: () => ({
                style: {
                    // color: '#030342',
                    backgroundColor: '#eff0fc',
                }
            }),
            // width: '300px',
            render: (text, record) => {
                // alert(JSON.stringify(record.strategy_id))
                const selectedStrategy = getStrategyById(record.strategy_id);
                if (!selectedStrategy) {
                    return <></>
                }
                return <strong>
                    {selectedStrategy.symbol.replace('USDT.P', '/USDT').replace('BINANCE:', '').replace('.P', '')}
                </strong>
            },
            // defaultSortOrder: 'ascend',
            sorter: (a, b) => a.symbol.localeCompare(b.symbol),
            ...getColumnSearchProps('symbol', 'Search by Symbol'),
        },
        {
            key: 'strategy',
            title: 'Strategy ID',
            dataIndex: 'strategy_id',
            align: 'center',
            // width: '300px',
            render: (text, record) => {
                // alert(JSON.stringify(record.strategy_id))
                const selectedStrategy = getStrategyById(record.strategy_id);
                if (!selectedStrategy) {
                    return <></>
                }

                return <>
                    <strong style={{color: '#e36e7c'}}>{selectedStrategy.id}</strong> {selectedStrategy.name}&nbsp;
                    <Tooltip
                        placement={"right"}
                        title={<StrategyCardLite strategy={{
                            'id': selectedStrategy.name + '-' + selectedStrategy.id,
                            'pnl_annual': selectedStrategy.net_profit_percentage,
                            'leverage': selectedStrategy.leverage,
                            'market': selectedStrategy.market,
                            'direction': selectedStrategy.direction,
                            'symbol': selectedStrategy.symbol.replace('BINANCE:', ''),
                            'draw_down': selectedStrategy.max_drawdown_percentage,
                            'max_loss_orders_session': selectedStrategy.max_loss_streak,
                            'win_rate': selectedStrategy.percent_profitable_all,
                            'profit_factor': selectedStrategy.profit_factor_all
                        }}/>}
                        overlayInnerStyle={{
                            width: '600px',
                            backgroundColor: '#d1d9da',
                            color: 'black',
                        }}
                    >
                        <InfoCircleTwoTone style={{cursor: 'pointer'}}/>
                        {/*&nbsp;<InfoCircleOutlined/>*/}
                        {/*<strong>*/}
                        {/*    {selectedStrategy.name}-{selectedStrategy.id} | {selectedStrategy.symbol.replace('BINANCE:', '').replace('.P', '')} | {selectedStrategy.timeframe}*/}
                        {/*    &nbsp;<InfoCircleOutlined/>*/}
                        {/*</strong>*/}
                    </Tooltip>
                </>
            },
            // defaultSortOrder: 'ascend',
            sorter: (a, b) => a.strategy_id - b.strategy_id,
            ...getColumnSearchProps('strategy_id', 'Search by Strategy ID'),
        },
        {
            key: 'loss_series_flag',
            title: 'Stability',
            dataIndex: 'loss_series_flag',
            // width: '15%',
            align: 'center',
            render: (_, record) => {
                const rec = getStrategyById(record.strategy_id);

                if (rec === undefined) return <></>

                const text = rec.loss_series_flag;

                return <>
                    {text === 0 ?
                        <Tooltip title={"HIGH Stability: The current losing streak is lower than the backtest."}><Tag
                            color={"#2cab1d"}>HIGH</Tag></Tooltip> : text === 1 ?
                            <Tooltip
                                title={"NORMAL Stability: The current losing streak is approaching the maximum losing streak with the backtest."}><Tag
                                color={"#0f0fa8"}>NORMAL</Tag></Tooltip> :
                            <Tooltip
                                title={"LOW Stability: The current losing streak has exceeded the maximum losing streak on the backtest. It is worth considering the option of disabling the strategy."}><Tag
                                color={"#b21a2d"}>LOW</Tag></Tooltip>
                    }
                </>
            },
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{
                        color: filtered ? '#f6adb5' : 'white',
                    }}
                />
            ),
            filters: [
                {text: 'HIGH', value: '0'},
                {text: 'NORMAL', value: '1'},
                {text: 'LOW', value: '2'},
                {text: 'ALL', value: '-1'},
            ],
            onFilter: (value, record) => value === '-1' || record.loss_series_flag + '' === value + '',
            // Optional: change how filter dropdown looks
            filterMultiple: false, // allows multiple selection
            // You can customize the dropdown style
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                <div style={{padding: 8}}>
                    <Select
                        style={{width: 188, marginBottom: 8, display: 'block'}}
                        placeholder="Select Stability Level"
                        value={selectedKeys[0]}
                        onChange={(value) => {
                            setSelectedKeys(value ? [value] : []);
                            confirm();
                        }}
                        options={[
                            {label: <Tag color={"black"}>ALL</Tag>, value: '-1'},
                            {label: <Tag color={"#2cab1d"}>HIGH</Tag>, value: '0'},
                            {label: <Tag color={"#0f0fa8"}>NORMAL</Tag>, value: '1'},
                            {label: <Tag color={"#b21a2d"}>LOW</Tag>, value: '2'},
                        ]}
                        dropdownStyle={{minWidth: 188}}
                    />
                </div>
            ),
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.loss_series_flag - b.loss_series_flag,
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.exchange.localeCompare(b.exchange)
            // ...getColumnSearchProps('direction', 'Search by Direction'),
        },
        {
            key: 'leverage',
            title: 'Leverage',
            dataIndex: 'leverage',
            align: 'center',
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '60px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.leverage - b.leverage,
            render: (text) => {
                if (text === 1) {
                    return <span style={{color: 'green', fontSize: 16, fontWeight: 700}}>{text}</span>
                } else {
                    if (text > 0) {
                        return <span style={{color: 'red', fontSize: 16, fontWeight: 700}}>{text}</span>
                    } else {
                        return <span style={{fontSize: 16, fontWeight: 700}}>From the strategy</span>
                    }
                }
            }
        },
        {
            key: 'margin_mode',
            title: 'Margin mode',
            dataIndex: 'margin_mode',
            align: 'center',
            // width: '60px',
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.margin_mode.localeCompare(b.margin_mode),
            render: (text) => {
                if (text === 'isolated') {
                    return <span style={{color: 'green', fontSize: 16, fontWeight: 600}}>{text}</span>
                } else {
                    return <span style={{color: 'red', fontSize: 16, fontWeight: 600}}>{text}</span>
                }
            },
        },
        {
            key: 'close_position_mode',
            title: 'Close Mode',
            dataIndex: 'close_position_mode',
            align: 'center',
            render: (text) => <strong>{text}</strong>,
            // width: '300px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.close_position_mode.localeCompare(b.close_position_mode)
        },
        {
            key: 'order_amount_usd',
            title: 'Order Amount, USD',
            dataIndex: 'order_amount_usd',
            align: 'center',
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '250px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.order_amount_usd - b.order_amount_usd,
            render: (text, record) => {
                if (text > 0) {
                    return <span style={{fontSize: 16, fontWeight: 700}}>{text}</span>
                } else {
                    return <span style={{fontSize: 16, fontWeight: 700}}>From the strategy</span>
                }
            }
        },
        {
            key: 'exchange',
            title: 'Exchange',
            dataIndex: 'exchange',
            align: 'center',
            width: '220px',
            render: (text, record) => {
                return <>
                    <strong style={{color: '#617af8'}}>{record.exchange_name}</strong><br/>
                    <Image height={"25px"} src={record.logo} preview={false}/>
                </>
            },
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.exchange.localeCompare(b.exchange),
            ...getColumnSearchProps('exchange_name', 'Search by Connector name'),
        },
        {
            key: 'total_open_trades_all',
            title: 'Equity curve',
            dataIndex: 'operation1',
            align: 'center',
            // fixed: 'right',
            // width: '50px',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <Image
                            src={record.equity_curve_url}
                            width={100}
                            height={50}
                            style={{borderRadius: 10}}
                            preview={true}
                        />
                    </>
                ) : null,
        },
        {
            key: 'tv_url',
            title: '',
            dataIndex: 'operation',
            align: 'center',
            fixed: 'right',
            onCell: () => ({
                style: {
                    backgroundColor: '#eff0fc',
                }
            }),
            // width: '220px',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                        <>
                            <Space direction={"horizontal"}>
                                <Tooltip title="Edit Copy Bot">
                                    <Button
                                        type={"primary"}
                                        size={"middle"}
                                        onClick={() => handleEdit(record)}>
                                        <EditOutlined/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Open TradingView chart">
                                    <Button
                                        type={"primary"}
                                        size={"middle"}
                                        // style={{
                                        //     // width: 140,
                                        //     // backgroundColor: 'blue',
                                        // }}
                                        // icon={<LinkOutlined/>}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!demoMode) {
                                                window.open(record.tv_url, '_blank');
                                            } else {
                                                showModalDemoWarning();
                                            }
                                        }}>
                                        <LinkOutlined/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Open Trades List">
                                    <Button
                                        type={"primary"}
                                        size={"middle"}
                                        // style={{
                                        //     // width: 90,
                                        //     // backgroundColor: 'blue',
                                        // }}
                                        // icon={<LinkOutlined/>}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedStrategyId([record.strategy_id]);
                                            setSelectedConnectorId(record.exchange_id);
                                            const strata = getStrategyById(record.strategy_id);
                                            setSelectedStrategyName(record.name + ' | ' + strata.name + '-' + strata.id + ' | ' + strata.symbol.replace('BINANCE:', '').replace('.P', '').replace('USDT', '/USDT') + ' | ' + ' TF: ' + strata.timeframe);
                                            setTradesListOpen(true);
                                        }}>
                                        <OrderedListOutlined/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Open API/Sgnals Logs">
                                    <Button
                                        type={"primary"}
                                        size={"middle"}
                                        // style={{
                                        //     // width: 90,
                                        //     // backgroundColor: 'blue',
                                        // }}
                                        // icon={<LinkOutlined/>}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedStrategyId([record.strategy_id]);
                                            setSelectedConnectorId(record.exchange_id);
                                            const strata = getStrategyById(record.strategy_id);
                                            setSelectedStrategyName(record.name + ' | ' + strata.name + '-' + strata.id + ' | ' + strata.symbol.replace('BINANCE:', '').replace('.P', '').replace('USDT', '/USDT') + ' | ' + ' TF: ' + strata.timeframe);
                                            setLogsListOpen(true);
                                        }}>
                                        <EyeOutlined/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Delete Copy Bot">
                                    <Popconfirm title="Sure to delete Copy Bot?"
                                                placement={"left"}
                                                trigger="click"
                                                onConfirm={() => handleDeleteBoot(record.ID)}
                                    >
                                        <Button type="text" size={"middle"} style={{background: '#e80e19', color: "white"}}>
                                            <DeleteOutlined style={{background: '#e80e19', color: "white"}}/>
                                        </Button>
                                    </Popconfirm>
                                </Tooltip>
                            </Space>
                        </>
                    ) :
                    null,
        },
    ];

    const handleSelectExchangeConnector = (value, option) => {
        // setSelectedStrategy(value);
        setUserSelectConnector(value);
        setCurrentConnectorId(value);
        setSelectedConnectorId(value);
    }

    const calculateAggregates = (data, column) => {
        if (!column.aggregate || !data?.length) return '-';

        let result;
        switch (column.aggregate) {
            case 'sum':
                result = data.reduce((sum, record) => sum + (Number(record[column.dataIndex]) || 0), 0);
                return formatNumber(result, column);

            case 'avg':
                const sum = data.reduce((acc, record) => acc + (Number(record[column.dataIndex]) || 0), 0);
                result = data.length ? sum / data.length : 0;
                return formatNumber(result, column);

            case 'min':
                result = Math.min(...data.map(record => Number(record[column.dataIndex]) || 0));
                return formatNumber(result, column);

            case 'max':
                result = Math.max(...data.map(record => Number(record[column.dataIndex]) || 0));
                return formatNumber(result, column);

            case 'custom':
                result = column.aggregateFunction?.(data);
                return typeof result === 'number' ? formatNumber(result, column) : result;

            case 'count':
                return `${column.summaryPrefix || ''}${data.length}${column.summarySuffix || ''}`;

            case 'none':
                return '-';

            default:
                return '-';
        }
    };

    const summaryRow = () => (
        <Table.Summary fixed>
            <Table.Summary.Row>
                {columns.map((column, index) => (
                    <Table.Summary.Cell
                        index={index}
                        key={column.key || column.dataIndex}
                        align={column.align}
                    >
                        <div style={{
                            backgroundColor: '#272762',
                            color: '#ffffff',
                            // padding: '8px 16px',
                            margin: '0px -16px',  // компенсуємо внутрішні відступи комірки
                            // height: '100%',
                        }}>
                            {column.aggregate ? (
                                <strong>
                                    {calculateAggregates(dataSource, column)}
                                </strong>
                            ) : index === 0 ? (
                                <strong>🟢 {
                                    dataSource.filter(item => item.is_active).length
                                }
                                </strong>
                            ) : index === 1 ? (
                                <strong>{`∑:${dataSource?.length || 0}`}</strong>
                            ) : '-'}
                        </div>
                    </Table.Summary.Cell>
                ))}
            </Table.Summary.Row>
        </Table.Summary>
    );

    const handleAddBotSingle = () => {
        setShowAddMultipleBotModal(false);
        if (demoMode && dataSource.length >= LIMIT_BOTS) {
            setShowAddBotModal(false);
            showModalLimitWarning();
            return;
        }
        getUserExchange();
        getStrategy();
        setInputBotName('');
        // setInputExchangeId('');
        setInputStrategyId(0);
        setInputOrderAmount(100);
        setInputLeverage(1);
        setInputMarginMode('isolated');
        if (!dataSourceExchange || dataSourceExchange.length === 0) {
            Modal.error({
                title: 'Error',
                content: 'You don\'t have any connected exchanges',
            });
        } else {
            setShowAddBotModal(true);
            setShowAddMultipleBotModal(false);
        }
    }

    return (
        <>
            {contextHolder}
            {!loading && <>
                {modalAddBotSingle()}
                {modalAddBotMultiple()}
                {modalEditBot()}
            </>}

            <Drawer
                title={
                    <span style={{fontSize: 22}}>
                        <OrderedListOutlined/> {selectedStrategyName}
                    </span>
                }
                placement={"bottom"}
                width={"100%"}
                height={'100%'}
                onClose={() => setTradesListOpen(false)}
                open={tradesListOpen}
            >
                <TradesList
                    strategy_id={selectedStrategyId}
                    show_extended={false}
                />
            </Drawer>
            <Drawer
                title={
                    <span style={{fontSize: 22}}>
                        <EyeOutlined/> {selectedStrategyName}
                    </span>
                }
                placement={"right"}
                width={"100%"}
                height={'100%'}
                onClose={() => setLogsListOpen(false)}
                open={logsListOpen}
            >
                <SignalLogs
                    strategy_id={selectedStrategyId}
                    connection_id={selectedConnectorId}
                    is_webhook={false}
                />
            </Drawer>
            {/*<Button*/}
            {/*    // type="primary"*/}
            {/*    style={{*/}
            {/*        backgroundColor: 'rgb(222,223,250)',*/}
            {/*        position: 'fixed',*/}
            {/*        // bottom: 70,*/}
            {/*        top: 10,*/}
            {/*        left: 210,*/}
            {/*        zIndex: 1000,*/}
            {/*        borderRadius: 50,*/}
            {/*        fontWeight: 700,*/}
            {/*        fontFamily: 'Terminal, monospace',*/}
            {/*        boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',*/}
            {/*    }}*/}
            {/*    onClick={() => setShowModal(true)}*/}
            {/*>*/}
            {/*    <InfoCircleOutlined/> {content_msg["How to set up copying trades"][language]}*/}
            {/*</Button>*/}
            <Modal
                open={showModal}
                title="How to set up copying trades from a specific strategy to a crypto exchange?"
                width={800}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
            >
                <div>
                    <iframe width="100%" height="382px"
                            src="https://embed.app.guidde.com/playbooks/3qnW38VbJ4HNsZXXF226m1"
                            title="How to set up copy trade" frameBorder="0" referrerPolicy="unsafe-url"
                            allowFullScreen="true" allow="clipboard-write"
                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"></iframe>
                    <p style={{display: "none"}}></p>
                </div>
            </Modal>
            <Content>
                <Row gutter={8} justify={"center"} align={"top"}>
                    <Col span={24}>
                        <span style={{
                            fontFamily: 'Courier New, monospace',
                            fontSize: '2em',
                            fontWeight: 700,
                        }}>
                                <AndroidOutlined/> Your bots to copy trades
                        </span>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Table: {
                                        // fontSize: "16px",
                                        fontFamily: 'Courier New',
                                        headerBorderRadius: '10px',
                                        headerColor: '#ffffff',
                                        headerBg: '#272762',
                                        bodySortBg: '#ffffff',
                                        headerSortHoverBg: '#272762',
                                        headerSortActiveBg: '#272762',
                                        cellFontSizeSM: "14px",
                                        cellPaddingInline: '10px',
                                        cellPaddingBlockSM: '5px',
                                        cellPaddingInlineSM: '5px',
                                        colorLinkActive: '#b21a2d',
                                    },
                                },
                            }}
                        >
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                // bordered
                                rowKey={record => record.id}
                                size="small"
                                scroll={{x: 'max-content'}}
                                pagination={{
                                    pageSizeOptions: [5, 7, 10, 20, 50, 100],
                                    hideOnSinglePage: true,
                                    showSizeChanger: true, // Включает выбор количества записей на странице
                                    defaultPageSize: 7,
                                    showTotal: (total) => `Total ${total} records`, // Показывает общее количество записей,
                                }}
                                loading={loading}
                                summary={summaryRow}
                                title={() =>
                                    <Row gutter={24} align={"middle"} justify={"space-between"}>
                                        <Col span={10}>
                                            <strong style={{color: '#0f0fa8'}}>Exchange
                                                Connector:</strong>&nbsp;&nbsp;
                                            <Select
                                                showSearch
                                                multiple={false}
                                                placeholder="Select Exchange Connector"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{width: 250}}
                                                onChange={handleSelectExchangeConnector}
                                                // value={selectedStrategy !== 0 ? {
                                                //     value: selectedStrategy.value,
                                                //     label: getStrategyById(selectedStrategy.value).name + '-' + selectedStrategy
                                                // } : null}
                                                defaultValue={{
                                                    value: 0,
                                                    label: 'All'
                                                }}
                                                options={[
                                                    {
                                                        value: 0,
                                                        label: 'All'
                                                    }, ...dataSourceExchange.map((item) => {
                                                        if (item.is_active) {
                                                            return {
                                                                value: item.key,
                                                                label: item.name
                                                            };
                                                        }
                                                        return null;
                                                    })].filter((item) => item !== null)}
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                onClick={handleAddBotSingle}
                                                type="primary"
                                                style={{
                                                    marginTop: '0%',
                                                    marginBottom: '0%',
                                                }}
                                            >
                                                <PlusCircleOutlined/> Create Copy Bot
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                                style={{
                                    marginTop: '0%',
                                    marginLeft: '1%',
                                    marginRight: '1%',
                                }}
                                onRow={(record) => {
                                    return {
                                        // onClick: () => handleEdit(record.ID), // click row
                                        onDoubleClick: () => handleEdit(record), // double click row
                                        // onContextMenu: event => {
                                        // }, // right button click row
                                        // onClick: event => {
                                        //     console.log(event)
                                        //     setInputStrategyId(record.strategy_id);
                                        //     // alert(getStrategyById())
                                        //     Modal.success({
                                        //         title: 'Success',
                                        //         width: 700,
                                        //         content: <StrategyCard strategy={getStrategyById()}/>,
                                        //     });
                                        // }, // mouse enter row
                                        // onMouseLeave: event => {
                                        // }, // mouse leave row
                                    };
                                }}
                            />
                        </ConfigProvider>
                    </Col>
                    <Col span={22}>
                        <EquityStats
                            title={<span style={{
                                fontSize: 22,
                                fontWeight: 600
                            }}><AreaChartOutlined/> Equity curve over&nbsp;&nbsp;
                                <Select
                                    defaultValue={equityStatsPeriod}
                                    style={{
                                        fontWeight: 700,
                                        width: 120
                                    }}
                                    onChange={(value) => {
                                        setEquityStatsPeriod(value);
                                    }}
                                >
                            <Option value={7}>7 days</Option>
                            <Option value={15}>15 days</Option>
                            <Option value={30}>30 days</Option>
                            <Option value={60}>60 days</Option>
                            <Option value={90}>90 days</Option>
                            <Option value={180}>180 days</Option>
                            <Option value={365}>1 year</Option>
                            <Option value={730}>2 years</Option>
                            <Option value={1095}>3 years</Option>
                            <Option value={1825}>5 years</Option>
                            <Option value={3650}>10 years</Option>
                        </Select>
                                &nbsp;&nbsp;(active strategies only)</span>}
                            equityCurveData={equityCurve}
                            max_loss_streak_count={maxLossStreakCount}
                            max_drawdown_in_series={maxDrawdownInSeries}
                            max_single_trade_loss={maxSingleTradeLoss}
                            max_open_trades_long={maxOpenTradesLong}
                            max_open_trades_short={maxOpenTradesShort}
                            max_open_trades_both={maxOpenTradesBoth}
                        />
                    </Col>
                    <Col span={22}>
                        {!isShowTradeTable &&
                            <Button
                                onClick={() => setIsShowTradeTable(true)}
                                type="primary"
                                style={{
                                    marginTop: '0%',
                                    marginBottom: '0%',
                                }}
                            >
                                <OrderedListOutlined/> Show Trades List
                            </Button>
                        }
                        {isShowTradeTable &&
                            <TradesList
                                strategy_id={strategy_ids}
                                show_extended={true}
                                period_in_days={equityStatsPeriod}
                            />
                        }
                    </Col>
                </Row>
            </Content>
        </>
    );
}

export default observer(BotsExchange);